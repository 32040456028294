/* Custom styles for the retro weather app */
.bg-zigzag {
  background: repeating-linear-gradient(
    45deg,
    #0f0c29,
    #0f0c29 10px,
    #302b63 10px,
    #302b63 20px
  );
}

.border-l-6 {
  border-left-width: 6px;
}

.border-r-6 {
  border-right-width: 6px;
}

.border-b-12 {
  border-bottom-width: 12px;
}

.animate-sunrise {
  animation: sunrise 3s infinite;
}

@keyframes sunrise {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(50px);
    opacity: 0;
  }
}

.bg-accent {
  background-color: #243b40;
}
.text-accent {
  color: #243b40;
}
.bg-primary {
  background-color: #ff8e78;
}
.hover\:bg-secondary:hover {
  background-color: #ffba6b;
}
.bg-tertiary {
  background-color: #75bebe;
}
.bg-quaternary {
  background-color: #e1b1a9;
}
.hover\:text-tertiary:hover {
  color: #75bebe;
}