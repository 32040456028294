.grid-container {
  display: grid;
  gap: 0.15625em; /* Reduced gap for thinner strokes */
  background-color: black;
  border: 0.35em solid black; /* Reduced border thickness */
  width: 100%;
  height: 100%;
  min-height: 40vw;
  min-width: 60vw;
}

.grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  width: 100%;
  height: 100%;
  border: 0.3125em solid black; /* Reduced border thickness */
  box-shadow: 0 0 0 0.3125em black; /* Reduced shadow thickness */
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .grid-container {
    min-height: 78vw;
    min-width: 80vw;
  }
}

